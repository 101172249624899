var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      attrs: {
        "heading-color": "accent darken-1",
        title: "Change Program Icon",
        scrollable: "",
        icon: "mdi-image",
        "hide-actions": ""
      },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "v-item-group",
        { attrs: { value: _vm.iconIndex }, on: { change: _vm.onSelect } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { wrap: "" } },
                [
                  _vm._l(_vm.icons, function(icon, index) {
                    return [
                      _c(
                        "v-col",
                        { key: index },
                        [
                          _c("v-item", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var active = ref.active
                                    var toggle = ref.toggle
                                    return [
                                      _c(
                                        "v-card",
                                        {
                                          style: active ? "opacity:0.6" : "",
                                          attrs: { elevation: active ? 20 : 6 },
                                          on: { click: toggle }
                                        },
                                        [
                                          _c("v-card-text", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex",
                                                staticStyle: { width: "74px" }
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: icon,
                                                    width: "64"
                                                  }
                                                }),
                                                _c("v-spacer"),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: active,
                                                        expression: "active"
                                                      }
                                                    ],
                                                    attrs: { small: "" }
                                                  },
                                                  [_vm._v("mdi-check-circle")]
                                                )
                                              ],
                                              1
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }