<template>
    <psi-dialog
        heading-color="accent darken-1"
        title="Change Program Icon"
        scrollable
        icon="mdi-image"
        @close="$emit('close')"
        hide-actions
    >
        <v-item-group :value="iconIndex" @change="onSelect">
            <v-container>
                <v-row wrap>
                    <template v-for="(icon, index) in icons">
                        <v-col :key="index">
                            <v-item v-slot="{ active, toggle }">
                                <v-card
                                    :elevation="active ? 20 : 6"
                                    @click="toggle"
                                    :style="active ? 'opacity:0.6' : ''"
                                >
                                    <v-card-text>
                                        <div class="d-flex" style="width: 74px">
                                            <img :src="icon" width="64" />
                                            <v-spacer></v-spacer>
                                            <v-icon v-show="active" small
                                                >mdi-check-circle</v-icon
                                            >
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-item>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </v-item-group>
    </psi-dialog>
</template>
<script>
export default {
    name: "program-icon-select",
    components: {},
    props: {
        icon_filepath: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            selectedIconFilePath: this.icon_filepath,
        };
    },
    computed: {
        icons() {
            let icon_filepaths = [];
            for (let i = 1; i <= 35; i++) {
                icon_filepaths.push(
                    `https://pacific-screening.imgix.net/programs/program-icon-${i}.jpg?auto=enhance,compress,format&width=200&q=60`
                );
            }
            return icon_filepaths;
        },
        iconIndex() {
            return this.icons.findIndex((icon) => icon === this.icon_filepath);
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        onSelect(index) {
            this.selectedIconFilePath = this.icons[index];
            this.$emit("select", this.selectedIconFilePath);
        },
    },
};
</script>

<style scoped>
</style>